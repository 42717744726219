import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as Emotion10ThemeProvider } from "@emotion/react";
import { brown } from "@mui/material/colors";
import RightFooter from "./components/RightFooter";
import MiddleFooter from "./components/MiddleFooter";
import LeftFooter from "./components/LeftFooter";

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#ee9ca7",
    },
    text: {
      primary: brown[700],
    },
  },
});
const Footer: React.FC = () => {
  return (
    <footer>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Emotion10ThemeProvider theme={theme}>
          <Box
            sx={{
              bgcolor: "primary.main",
              color: "text.primary",
              pt: 8,
              pb: 8,
              borderTop: `4px solid ${brown[700]}`,
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={6} sx={{ direction: "rtl" }}>
                <RightFooter />
                <MiddleFooter />
                <LeftFooter />
              </Grid>
              <Box
                sx={{ borderBottom: `2px solid ${brown[700]}`, mt: 6, mb: 4 }}
              ></Box>
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <Typography variant="body2" color="inherit">
                  © {new Date().getFullYear()} בר מתוקים. כל הזכויות שמורות.
                </Typography>
              </Box>
            </Container>
          </Box>
        </Emotion10ThemeProvider>
      </ThemeProvider>
    </footer>
  );
};

export default Footer;
