import React, { useState, ChangeEvent } from "react";

import { postImages } from "../services/imageApi";
import { useSnack } from "../../providers/SnackbarProvider";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Input from "@mui/material/Input";
import ROUTES from "../../routes/routessModel";
import { useUser } from "../../user/provider/UserProvider";

const UploadImages: React.FC = () => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const { cardId } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const snack = useSnack();
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    if (!files) {
      alert("Please select files to upload.");

      return;
    } else navigate(ROUTES.ROOT);

    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("images", file);
    });

    try {
      setUploading(true);
      if (cardId) await postImages(cardId, formData);
      snack("success", "תמונה חדשה עלתה בהצלחה");
      setFiles(null);
    } catch (error) {
      console.error("Error uploading images:", error);
      snack("error", "יש בעיה");
    } finally {
      setUploading(false);
    }
  };
  if (!user || !user.isAdmin) return <Navigate replace to={ROUTES.ROOT} />;

  return (
    <Box
      minHeight="90vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        flexDirection="column"
        gap={2}
        sx={{
          padding: 3,
          border: "1px solid #ccc",
          borderRadius: 2,
          maxWidth: 500,
          margin: "auto",
          boxShadow: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography dir="rtl" variant="h6" component="div">
          העלאת תמונה
        </Typography>

        <Input
          type="file"
          onChange={handleFileChange}
          disableUnderline
          inputProps={{ accept: "image/*", multiple: true }}
          sx={{
            width: "100%",
            padding: 1,
            borderRadius: 1,
            border: "1px solid #ccc",
            backgroundColor: "#fff",
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={uploading || !files}
          sx={{ width: "100%", padding: 1.5 }}
        >
          {uploading ? <CircularProgress size={24} color="inherit" /> : "אישור"}
        </Button>
      </Box>
    </Box>
  );
};

export default UploadImages;
