import React from "react";
import MuiCard from "@mui/material/Card";
import CardHead from "./CardHead";
import CardBody from "./Cardbody";
import CardInterface from "../../interface/CardInterface";
import { CardActionArea } from "@mui/material";
import CardActionBar from "./CardActionBar";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../routes/routessModel";
import { useUser } from "../../../user/provider/UserProvider";

type Props = {
  card: CardInterface;
  onDelete: (id: string) => any;
};
const Card: React.FC<Props> = ({ card, onDelete }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  return (
    <MuiCard
      sx={{
        minWidth: 100,

        backgroundColor: "#ffff",
        borderRadius: "10px",
        // border,
      }}
    >
      <CardActionArea
        onClick={() => {
          navigate(`${ROUTES.PRODUCTS_DETAILS}/${card._id}`);
        }}
      >
        <CardHead imageUrl={card.imageUrl} />
        <CardBody card={card} />
      </CardActionArea>

      {user?.isAdmin && <CardActionBar onDelete={onDelete} cardId={card._id} />}
    </MuiCard>
  );
};

export default Card;
