import React from "react";
import NavItemSlick from "../../components/NavItemSilck";
type Props = {
  to: string;
  label: string;
};
const CategoriesButton: React.FC<Props> = ({ to, label }) => {
  return (
    <>
      <NavItemSlick label={label} to={to} />
    </>
  );
};

export default CategoriesButton;
