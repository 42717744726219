import React from "react";
import Container from "@mui/material/Container";
import { Navigate } from "react-router-dom";

import Form from "../../forms/components/Form";
import Input from "../../forms/components/Input";

import initialLoginForm from "../helpers/initialFromLogin";
import ROUTES from "../../routes/routessModel";
import loginSchema from "../models/joi/loginSchema";
import useHandleUser from "../hooks/useHandleUser";
import useFormlogin from "../../forms/hooks/useFormlogin";

const LoginPage = () => {
  const {
    handleLogin,
    value: { user },
  } = useHandleUser();
  const { value, ...rest } = useFormlogin(
    initialLoginForm,
    loginSchema,
    handleLogin
  );

  const { onSubmit, handleChange, handleReset, validateForm } = rest;

  const { data, errors } = value;
  if (user) return <Navigate replace to={ROUTES.ROOT} />;

  return (
    <Container
      sx={{
        // mt: 8,
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        title="כניסה"
        onSubmit={onSubmit}
        onFormChange={validateForm}
        onReset={handleReset}
        spacing={1}
        styles={{ maxWidth: "450px" }}
      >
        <Input
          type="email"
          name="email"
          data={data}
          label="שם משתמש"
          onInputChange={handleChange}
          error={errors.email}
          breakPoints={{ xs: 12, md: 6 }}
        />
        <Input
          type="password"
          name="password"
          data={data}
          label="סיסמה"
          onInputChange={handleChange}
          error={errors.password}
          breakPoints={{ xs: 12, md: 6 }}
        />
      </Form>
    </Container>
  );
};

export default LoginPage;
