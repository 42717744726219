import React, { ReactNode } from "react";
import Paper from "@mui/material/Paper";

type Props = {
  children: ReactNode;
};

const Main: React.FC<Props> = ({ children }) => {
  return (
    <Paper
      sx={{
        mt: 9.5,
        minHeight: "90vh",

        background: "#f7f7f7",
      }}
    >
      {children}
    </Paper>
  );
};

export default Main;
