import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { brown, pink } from "@mui/material/colors";
import CategoriesMenu from "../../components/categoriesMenu";

const Categories = () => {
  return (
    <Box
      sx={{
        minHeight: "90vh",
        bgcolor: "rgba(251, 231, 239)",

        position: "relative",
        py: 10,
        px: 2,
        borderTop: `4px solid ${brown[700]}`,
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            textAlign: "center",
            mb: 6,
            position: "relative",
            zIndex: 2,
            "&::after": {
              content: '""',
              width: "100px",
              height: "4px",
              background: pink[400],
              display: "block",
              margin: "20px auto 0",
              borderRadius: "2px",
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              color: brown[800],
            }}
          >
            המוצרים שלנו
          </Typography>
        </Box>
        <Box>
          <CategoriesMenu />
        </Box>
      </Container>
    </Box>
  );
};

export default Categories;
