import { CardContent, Typography } from "@mui/material";
import React from "react";
import CardInterface from "../../interface/CardInterface";

type Props = { card: CardInterface };

const CardBody: React.FC<Props> = ({ card }) => {
  return (
    <CardContent sx={{ pb: 1 }}>
      <Typography
        sx={{
          p: 0,
          mb: 0.5,
          direction: "rtl",
          typography: { lg: "h5", md: "h6", s: "h6", xs: "body1" },
        }}
      >
        {card.title}
      </Typography>

      <Typography
        component="div"
        color="#ec407a"
        sx={{
          p: 0,
          direction: "rtl",
          typography: {
            lg: "subtitle2",
            md: "subtitle2",
            s: "subtitle2",
            xs: "subtitle2",
          },
        }}
      >
        {card.price} ₪
      </Typography>
    </CardContent>
  );
};

export default CardBody;
