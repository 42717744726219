import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { brown } from "@mui/material/colors";

type Props = {
  to: string;
  children: ReactNode;
  color?: string;
};
const MediumBrown = brown[700];
const NavBarLink: React.FC<Props> = ({ to, children, color = MediumBrown }) => {
  return (
    <Link
      to={to}
      style={{
        color,
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Link>
  );
};

export default NavBarLink;
