import React, { useEffect } from "react";
import { Container } from "@mui/material";
import CardsFeedBack from "../components/CardsFeedBack";
import useCards from "../hooks/useCards";

const CardByCategory = () => {
  const {
    cards,
    error,
    isLoading,
    handleGetCardsByCategory,
    handleDeleteCard,
  } = useCards();
  const herf = window.location.href;
  const category = herf.substring(herf.lastIndexOf("/") + 1);

  useEffect(() => {
    handleGetCardsByCategory(category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [herf]);
  const onDeleteCard = async (cardId: string) => {
    await handleDeleteCard(cardId);
    await handleGetCardsByCategory(category);
  };
  return (
    <Container>
      <CardsFeedBack
        onDelete={onDeleteCard}
        isLoading={isLoading}
        error={error}
        cards={cards}
      />
    </Container>
  );
};

export default CardByCategory;
