import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useCards from "../hooks/useCards";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import ROUTES from "../../routes/routessModel";

const CardDetailsPage = () => {
  const { cardId } = useParams();
  const navigate = useNavigate();
  const { card, error, isLoading, handleGetCard } = useCards();

  useEffect(() => {
    if (cardId) handleGetCard(cardId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Spinner />;
  if (error) return <Error errorMessage={error} />;
  if (!isLoading && !card) return <p>no card found ...</p>;

  if (card)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
          backgroundImage:
            "linear-gradient( rgba(255, 243, 249, 0.8), rgba(255, 243, 249, 0.8)),url(/assets/images/backGroundPic.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: 5,
          px: 2,
          position: "relative",
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Box dir="rtl">
                <img
                  width="100%"
                  height="100%"
                  src={card.imageUrl}
                  alt="imageCard"
                  style={{ border: "5px solid #555" }}
                />
                <Button
                  sx={{
                    color: "#b5816b",
                    borderBottom: "4px solid #ee9ca7",
                    "&:hover": {
                      backgroundColor: "#ee9ca7",
                      color: "#b5816b",
                    },
                  }}
                  onClick={() => {
                    navigate(`${ROUTES.IMAGES}/${card._id}`);
                  }}
                >
                  <Typography variant="h6"> עוד תמונות</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box sx={{ direction: "rtl" }}>
                <Typography
                  variant="h2"
                  fontWeight={500}
                  color="#b5816b"
                  sx={{ WebkitTextStroke: "0.6px black" }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="h4"
                  mt={1}
                  fontWeight={300}
                  color="#ee9ca7"
                  sx={{ WebkitTextStroke: "0.6px black" }}
                >
                  {card.subtitle}{" "}
                </Typography>

                <Typography
                  mt={2}
                  variant="h5"
                  color="black"
                  sx={{ WebkitTextStroke: "0.6px black" }}
                >
                  מחיר : {card.price} ₪
                </Typography>
                <Typography
                  mt={1}
                  variant="body2"
                  color="black"
                  // sx={{ WebkitTextStroke: "0.6px black" }}
                >
                  * אפשר התאמה לאנשים אלרגים
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  return null;
};

export default CardDetailsPage;
