import React from "react";
import TopHomePage from "./components/TopHomePage/TopHomePage";

import SlickCards from "./components/silckCards/SlickCards";
import AboutPage from "../aboutPage/AboutPage";

const HomePage = () => {
  return (
    <>
      <TopHomePage />

      <AboutPage />
      <SlickCards />
    </>
  );
};

export default HomePage;
