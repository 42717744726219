import React from "react";
import useCards from "../hooks/useCards";
import { Navigate } from "react-router-dom";
import useForm from "../../forms/hooks/useForm";
import Container from "@mui/material/Container";
import CreateCardForm from "../components/CreateCardForm";
import cardSchema from "../models/joi/CardSchema";
import initialCard from "../helpers/initialCard";
import ROUTES from "../../routes/routessModel";
import { useUser } from "../../user/provider/UserProvider";

const CreateCardPage = () => {
  const { HandleCreateCard } = useCards();
  const { user } = useUser();
  const { value, ...rest } = useForm(initialCard, cardSchema, HandleCreateCard);
  const { data, errors } = value;
  const { handleChange, handleReset, onSubmit, validateForm, handleOnChage } =
    rest;

  if (!user || !user.isAdmin) return <Navigate replace to={ROUTES.ROOT} />;

  return (
    <Container>
      <CreateCardForm
        title="מוצר חדש"
        data={data}
        errors={errors}
        onFormChange={validateForm}
        onInputChange={handleChange}
        onReset={handleReset}
        onSubmit={onSubmit}
        handleOnChage={handleOnChage}
      />
    </Container>
  );
};

export default CreateCardPage;
