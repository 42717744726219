import React, { FC, ChangeEvent } from "react";
import Form from "../../forms/components/Form";
import Input from "../../forms/components/Input";
import Joi from "joi";

import { Container } from "@mui/material";
import { CardTypeToDB, CreateCardErrors } from "../models/types/CardTypes";
import SelectInput from "../../forms/components/SelectInput";

type Props = {
  title?: string;
  onSubmit: () => void;
  onReset: () => void;
  onFormChange: () => Joi.ValidationError | null;
  errors: CreateCardErrors;
  data: CardTypeToDB; // Ensure this matches the updated type
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnChage: (e: ChangeEvent<HTMLInputElement>) => void;
};

const CardForm: FC<Props> = ({
  onSubmit,
  onReset,
  onFormChange,
  title,
  errors,
  data,
  onInputChange,
  handleOnChage,
}) => {
  return (
    <Container
      sx={{
        // mt: 8,
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        onSubmit={onSubmit}
        onReset={onReset}
        onFormChange={onFormChange}
        styles={{ maxWidth: "800px" }}
        title={title}
      >
        <Input
          name="title"
          label="שם המוצר"
          error={errors.title}
          onInputChange={onInputChange}
          data={data}
          breakPoints={{ xs: 12 }}
        />
        <Input
          name="subtitle"
          label="תיאור המוצר"
          error={errors.subtitle}
          onInputChange={onInputChange}
          data={data}
          breakPoints={{ xs: 12 }}
        />

        <Input
          name="price"
          label="מחיר"
          error={errors.price}
          onInputChange={onInputChange}
          data={data}
          breakPoints={{ xs: 12 }}
          required={true}
        />

        <SelectInput
          name="category"
          label="קטגוריה"
          error={errors.category}
          breakPoints={{ xs: 12 }}
          onInputChange={onInputChange}
          data={data}
        />
        <input
          type="file"
          name="imageUrl"
          onChange={handleOnChage}
          accept="image/*"
        />
      </Form>
    </Container>
  );
};

export default React.memo(CardForm);
