import { Grid, Typography } from "@mui/material";
import React from "react";

const LeftFooter = () => {
  return (
    <Grid item xs={12} md={4} sx={{ textAlign: "left" }}>
      <Typography variant="h5" gutterBottom fontWeight="bold">
        שעות פעילות
      </Typography>
      <Typography variant="body1">ראשון - חמישי: 09:00 - 18:00</Typography>
      <Typography variant="body1">שישי: 09:00 - 13:00</Typography>
      <Typography variant="body1">שבת: סגור</Typography>
    </Grid>
  );
};

export default LeftFooter;
