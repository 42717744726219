import { useState, useCallback, useMemo, ChangeEvent } from "react";
import Joi, { PartialSchemaMap } from "joi";
import { Await } from "react-router-dom";

type HandleSubmit = (data: any) => void | Promise<void>;

const useForm = <TForm extends Record<string, string | File | null | object>>(
  initialForm: TForm,
  schema: PartialSchemaMap<any>,
  handleSubmit: HandleSubmit
) => {
  const [data, setData] = useState(initialForm);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [file, setFile] = useState<File | undefined>();

  const handleReset = useCallback(() => {
    setData(initialForm);
    setErrors({});
  }, [initialForm]);

  type TargetType = { name: string; value: string };

  const validateProperty = useCallback(
    ({ name, value }: TargetType) => {
      const obj = { [name]: value };
      const generateSchema = Joi.object({ [name]: schema[name] });
      const { error } = generateSchema.validate(obj);
      return error ? error.message : null;
    },
    [schema]
  );

  const handleOnChage = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFile(files[0]);
      setData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setData((prev) => ({ ...prev, [name]: null })); // Handle null case
    }
  };

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;

      const errorMessage = validateProperty(target);
      if (errorMessage)
        setErrors((prev) => ({ ...prev, [name]: errorMessage }));
      else
        setErrors((prev) => {
          let obj: Record<string, string> = { ...prev };
          delete obj[name];
          return obj;
        });

      setData((prev) => ({ ...prev, [name]: value }));
    },
    [validateProperty]
  );

  const validateForm = useCallback(() => {
    const schemaForValidate = Joi.object(schema);
    const { error } = schemaForValidate.validate(data);
    if (error) return error;
    return null;
  }, [schema, data]);

  const onSubmit = useCallback(async () => {
    const formData = new FormData();
    if (file) {
      formData.append("imageUrl", file);
    }

    // Object.keys(data).forEach((key) => {
    //   if (data[key] !== undefined && data[key] !== null) {
    //     if (typeof data[key] === "object") {
    //       formData.append(key, JSON.stringify(data[key])); // Convert object to JSON string
    //     } else {
    //       formData.append(key, data[key]);
    //     }
    //   }
    // });
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value !== undefined && value !== null) {
        if (typeof value === "object") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    });

    try {
      await handleSubmit(formData);
    } catch (error) {
      console.error("Submit Error:", error);
    }
  }, [data, file, handleSubmit]);

  const value = useMemo(() => {
    return { data, errors };
  }, [data, errors]);

  return {
    value,
    onSubmit,
    handleChange,
    handleReset,
    validateForm,
    setData,
    handleOnChage,
  };
};

export default useForm;
