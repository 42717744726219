import { Grid, Typography } from "@mui/material";
import React from "react";

const RightFooter = () => {
  return (
    <Grid item xs={12} md={4} sx={{ textAlign: "right" }}>
      <Typography variant="h4" gutterBottom fontWeight="bold">
        בר מתוקים
      </Typography>
      <Typography variant="body1" gutterBottom>
        רחוב הנשיא 38, חדרה
      </Typography>
      <Typography variant="body1" gutterBottom>
        טלפון: 052-8488123
      </Typography>
      <Typography variant="body1">אימייל: info@bar-metokim.com</Typography>
    </Grid>
  );
};

export default RightFooter;
