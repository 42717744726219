const initialCard = {
  title: "",
  subtitle: "",

  imageUrl: null,
  price: "",
  category: "",
};

export default initialCard;
