// import React, { ChangeEvent, FC } from "react";
// import TextField from "@mui/material/TextField";
// import { makeFirstLetterCapital } from "../utils/algoMethods";
// import Grid from "@mui/material/Grid";

// type BreakPointsKeyTypes = "xs" | "sm" | "md" | "lg" | "xl";
// type VariantType = "filled" | "outlined" | "standard";
// type BreakPointsValueTypes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
// type Props = {
//   variant?: VariantType;
//   type?: string;
//   name: string;
//   data: Record<string, unknown>;
//   label?: string;
//   required?: boolean;
//   error?: string | File;
//   onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
//   breakPoints?: Partial<Record<BreakPointsKeyTypes, BreakPointsValueTypes>>;
// };

// const Input: FC<Props> = ({
//   variant = "outlined",
//   type = "text",
//   name,
//   data,
//   label = "",
//   required = true,
//   error,
//   onInputChange,
//   breakPoints = { xs: 12 },
// }) => {
//   return (
//     <Grid item {...breakPoints}>
//       <TextField
//         variant={variant}
//         label={makeFirstLetterCapital(label)}
//         type={type}
//         id={name}
//         name={name}
//         value={data[name] ? data[name] : ""}
//         required={required}
//         helperText={typeof error == "string" && error}
//         error={!!error}
//         onChange={onInputChange}
//         fullWidth
//         autoComplete="off"
//       />
//     </Grid>
//   );
// };

// export default React.memo(Input);

import React, { ChangeEvent, FC } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

type BreakPointsKeyTypes = "xs" | "sm" | "md" | "lg" | "xl";
type VariantType = "filled" | "outlined" | "standard";
type BreakPointsValueTypes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type Props = {
  variant?: VariantType;
  type?: string;
  name: string;
  data: Record<string, unknown>;
  label?: string;
  required?: boolean;
  error?: string | File;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnChage?: (e: ChangeEvent<HTMLInputElement>) => void;
  breakPoints?: Partial<Record<BreakPointsKeyTypes, BreakPointsValueTypes>>;
};

const Input: FC<Props> = ({
  variant = "outlined",
  type = "text",
  name,
  data,
  label = "",
  required = true,
  error,
  onInputChange,
  breakPoints = { xs: 12 },
}) => {
  return (
    <Grid item {...breakPoints}>
      <TextField
        variant={variant}
        label={label.charAt(0).toUpperCase() + label.slice(1)}
        type={type}
        id={name}
        name={name}
        value={
          type === "file"
            ? undefined
            : data[name]
            ? typeof data[name] === "string"
              ? data[name]
              : ""
            : ""
        }
        required={required}
        helperText={typeof error === "string" ? error : undefined}
        error={!!error}
        onChange={onInputChange}
        fullWidth
        autoComplete="off"
        InputProps={{
          inputProps: { accept: type === "file" ? "image/*" : undefined },
        }}
      />
    </Grid>
  );
};

export default React.memo(Input);
