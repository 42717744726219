import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
const MiddleFooter = () => {
  return (
    <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
      <Typography variant="h5" gutterBottom fontWeight="bold">
        בואו לבקר אותנו
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ justifyContent: "center" }}
      >
        <Typography
          variant="body1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          ניווט באמצעות Waze
          <IconButton
            aria-label="Navigate with Waze"
            href="https://waze.com/ul?ll=32.44048068106908,34.91382851521554&navigate=yes"
            target="_blank"
            rel="noopener"
            sx={{
              bgcolor: "#ee9ca7",
              borderRadius: "50%",
              padding: 1,
            }}
          >
            <img
              src="/assets/icons/waze.svg"
              alt="Waze"
              style={{ width: 40, height: 40 }}
            />
          </IconButton>
        </Typography>
      </Stack>

      <Box sx={{ textAlign: "center", mt: 4 }}>
        <IconButton
          aria-label="Instagram"
          color="inherit"
          href="https://www.instagram.com/bar_metokim22?igsh=aDI4ZXZldTdxaW1u"
          target="_blank"
          rel="noopener"
        >
          <InstagramIcon fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="Facebook"
          color="inherit"
          href="https://www.facebook.com/profile.php?id=100052925482283&mibextid=uzlsIk"
          target="_blank"
          rel="noopener"
        >
          <FacebookIcon fontSize="large" />
        </IconButton>
      </Box>
    </Grid>
  );
};

export default MiddleFooter;
