import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { brown } from "@mui/material/colors";
import LeftBackGroundImg from "./components/LeftBackGroundImg";
import AboutContent from "./components/AboutContent";
import RightBackGroundImg from "./components/RightBackGroundImg";

const theme = createTheme({
  typography: {
    fontFamily: "Heebo, sans-serif",
  },
});

const AboutUs: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "90vh",
          background: `linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%)`,
          py: 10,
          px: 2,
          position: "relative",
          borderTop: `4px solid ${brown[700]}`,
        }}
      >
        <LeftBackGroundImg />
        <AboutContent />
        <RightBackGroundImg />
      </Box>
    </ThemeProvider>
  );
};

export default AboutUs;
