import { Box } from "@mui/material";
import React from "react";

const LeftBackGroundImg = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: { xs: -90, md: -100 },
        left: { xs: "15%", md: "10%" },
        transform: "translateX(-50%)",
        width: { xs: "220px", md: "300px" },
        height: { xs: "220px", md: "300px" },
        background: `url('/assets/images/cakebgc1.png') no-repeat center center`,
        backgroundSize: "cover",
        borderRadius: "50%",
        zIndex: 1,
      }}
    />
  );
};

export default LeftBackGroundImg;
