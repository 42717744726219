import React, { useState } from "react";
import { Box, Drawer, IconButton, List, ListItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { brown } from "@mui/material/colors";
import ROUTES, { CATEGORY_BTN } from "../../../../routes/routessModel";
import NavItem from "../../../components/NavItem";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import CakeIcon from "@mui/icons-material/Cake";
import InfoIcon from "@mui/icons-material/Info";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../user/provider/UserProvider";
const MoreButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();
  const toggleDrawer =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(isOpen);
    };
  const handleListItemClick = () => {
    setOpen(false);
  };
  return (
    <Box>
      <IconButton onClick={toggleDrawer(true)} sx={{ color: brown[700] }}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#ee9ca7",
            width: 250,
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={toggleDrawer(false)} sx={{ color: brown[700] }}>
            <CloseIcon sx={{ border: `2px solid ${brown[700]}` }} />
          </IconButton>
        </Box>
        <List sx={{ direction: "rtl" }} onClick={handleListItemClick}>
          <ListItem>
            <IconButton
              onClick={() => {
                navigate(`${ROUTES.ROOT}`);
              }}
            >
              <HomeIcon />
            </IconButton>
            <NavItem to={ROUTES.ROOT} label="ראשי" />
          </ListItem>

          <ListItem sx={{ borderTop: `2px solid ${brown[700]}` }}>
            <IconButton
              onClick={() => {
                navigate(`${CATEGORY_BTN.ALL_PRODUCTS}`);
              }}
            >
              <CakeIcon />
            </IconButton>
            <NavItem to={CATEGORY_BTN.ALL_PRODUCTS} label="המוצרים שלנו" />
          </ListItem>
          <ListItem
            sx={{
              borderTop: `2px solid ${brown[700]}`,
              borderBottom: `2px solid ${brown[700]}`,
            }}
          >
            <IconButton
              onClick={() => {
                navigate(`${ROUTES.ABOUT}`);
              }}
            >
              <InfoIcon />
            </IconButton>
            <NavItem to={ROUTES.ABOUT} label="אודות" />
          </ListItem>

          {user?.isAdmin && (
            <ListItem
              sx={{
                borderTop: `2px solid ${brown[700]}`,
                borderBottom: `2px solid ${brown[700]}`,
              }}
            >
              <NavItem to={ROUTES.CREATE_CARD} label="הוסף עוגה" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default MoreButton;
