import React, { useEffect } from "react";
import { Container } from "@mui/material";
import CardsFeedBack from "../components/CardsFeedBack";
import useCards from "../hooks/useCards";
import { getToken } from "../../user/services/localStorage";

const CardsPagee = () => {
  const { cards, error, isLoading, handleGetCards, handleDeleteCard } =
    useCards();

  useEffect(() => {
    handleGetCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDeleteCard = async (cardId: string) => {
    await handleDeleteCard(cardId);
    await handleGetCards();
  };
  return (
    <Container>
      <CardsFeedBack
        onDelete={onDeleteCard}
        isLoading={isLoading}
        error={error}
        cards={cards}
      />
    </Container>
  );
};

export default CardsPagee;
