import axios from "axios";
import { ImageData } from "../interface/imageInterface";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8181";
export const postImages = async (cardId: string, formData: FormData) => {
  try {
    const { data } = await axios.post<[]>(
      `${apiUrl}/getImages/upload/${cardId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(data);
  } catch (error) {
    if (axios.isAxiosError(error)) return Promise.reject(error.message);
    console.error(error);

    return Promise.reject("An unexpected error occurred!");
  }
};
export const getImages = async (cardId: string | undefined) => {
  try {
    const { data } = await axios.get<ImageData[]>(
      `${apiUrl}/getImages/images/${cardId}`
    );
    return Promise.resolve(data);
  } catch (error) {
    if (axios.isAxiosError(error)) return Promise.reject(error.message);
    console.error(error);

    return Promise.reject("An unexpected error occurred!");
  }
};
export const deleteImage = async (imageId: string) => {
  try {
    const { data } = await axios.delete(
      `${apiUrl}/getImages/deleteimage/${imageId}`
    );
    return Promise.resolve(data);
  } catch (error) {
    if (axios.isAxiosError(error)) return Promise.reject(error.message);
    return Promise.reject("An unexpected error occurred!");
  }
};
