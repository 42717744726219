import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";

const ErrorPage = () => {
  return (
    <Container>
      <Box minHeight="100vh" alignItems="center" display="flex">
        <Grid justifyContent="center" container>
          <Grid lg={12} md={12} sm={12} item>
            <Box display="flex" justifyContent="center" color="#636b6f">
              <Typography variant="h4" component="h1" fontWeight={400} p={1}>
                404
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography mt={0.6} variant="h5" component="h2" p={1}>
                not found
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <img
              style={{ opacity: 0.58 }}
              width="100%"
              src="/assets/images/broken-robot.png"
              alt="broken robot"
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ErrorPage;
