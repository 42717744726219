import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import RightNavBar from "./right-navigation/RightNavBar";
import MiddleNavBar from "./middle-navigation/MiddleNavBar";
import LeftNavBarLogo from "./left-navigation/LeftNavBarLogo";
export const NavBar = () => {
  return (
    <AppBar
      sx={{
        backgroundColor: "#ee9ca7",
        position: "-moz-initial",
        top: 0,
        // bottom: 813,
        height: 76,
      }}
      elevation={5}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <LeftNavBarLogo />
        <MiddleNavBar />
        <RightNavBar />
      </Toolbar>
    </AppBar>
  );
};
// backdropFilter: "blur(3px)",
