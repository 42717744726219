import React from "react";
import Box from "@mui/material/Box";

import MoreButton from "./MoreButton";

const RightNavBar = () => {
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "inline-flex" } }}></Box>
      <Box sx={{ display: { xs: "inline-flex", md: "none" } }}>
        <MoreButton />
      </Box>
    </>
  );
};

export default RightNavBar;
