import React, { useCallback, useState } from "react";
import { deleteImage } from "../services/imageApi";
import { useSnack } from "../../providers/SnackbarProvider";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../routes/routessModel";
type Props = { imageId: string };

const DeleteImage: React.FC<Props> = ({ imageId }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const snack = useSnack();
  const navigate = useNavigate();
  const handleDeleteImage = useCallback(async (imageId: string) => {
    try {
      setUploading(true);
      await deleteImage(imageId);
      snack("success", "התמונה נמחקה בהצלחה");
      setUploading(false);
      navigate(`${ROUTES.ROOT}`);
    } catch (error) {
      console.error("Error uploading images:", error);
      snack("error", "יש בעיה");
    }
  }, []);
  return (
    <Box>
      <IconButton
        onClick={() => handleDeleteImage(imageId)}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default DeleteImage;
