import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";
import Layout from "./layout/Layout";
import { useEffect, useState } from "react";
import LoadingPage from "./pages/loadingpage/LoadingPage";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import ScrollToTop from "./components/ScrollToTop";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import UserProvider from "./user/provider/UserProvider";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Arimo", "sans-serif"].join(","),
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 8000);
    };
    fakeDataFetch();
  }, []);
  return (
    <div className="App">
      {" "}
      {isLoading ? (
        <LoadingPage />
      ) : (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop />
            <SnackbarProvider>
              <UserProvider>
                <Layout>
                  <Router />
                </Layout>
              </UserProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </ThemeProvider>
      )}
    </div>
  );
}

export default App;
