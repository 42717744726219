import React from "react";
import { CardMedia } from "@mui/material";

type Props = { imageUrl: string };

const CardHead: React.FC<Props> = ({ imageUrl }) => {
  return (
    <CardMedia
      component="img"
      image={imageUrl}
      sx={{
        minHeight: "25vh",

        width: "100%",
        aspectRatio: "16/9",
        objectFit: "cover",
        transition: "transform 0.3s ease-in-out",
        "&:hover": { transform: "scale(1.05)" },
      }}
    />
  );
};

export default CardHead;
