import React, { ReactNode } from "react";
import Header from "./header/Header";
import Main from "./main/Main";
import Footer from "./footer/Footer";
import { Fab } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
type Props = {
  children: ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <Main>
        {children}{" "}
        <Fab
          href="https://api.whatsapp.com/message/HANDRDQELLYPH1?autoload=1&app_absent=0"
          aria-label="add"
          sx={{
            bgcolor: "#25D366",
            color: "white",
            position: "fixed",
            bottom: 25,
            right: 15,
          }}
        >
          <WhatsAppIcon />
        </Fab>
      </Main>
      <Footer />
    </>
  );
};

export default Layout;
