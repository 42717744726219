import { Box, Typography } from "@mui/material";
import { brown } from "@mui/material/colors";
import React from "react";
import NavItem from "../../../components/NavItem";
import ROUTES, { CATEGORY_BTN } from "../../../../routes/routessModel";
import { useUser } from "../../../../user/provider/UserProvider";

const MiddleNavBar = () => {
  const LightBrown = brown[300];
  const { user } = useUser();

  return (
    <Box sx={{ width: 700 }}>
      <Box
        sx={{
          justifyContent: "space-around",
          display: { xs: "none", md: "flex" },
        }}
      >
        {user?.isAdmin && <NavItem to={ROUTES.CREATE_CARD} label="הוסף עוגה" />}
        {user?.isAdmin && (
          <Typography color={LightBrown} sx={{ mt: 0.5 }}>
            _
          </Typography>
        )}
        {/* <NavItem to={ROUTES.ABOUT} label="כשרות" />
        <Typography color={LightBrown} sx={{ mt: 0.5 }}>
          _
        </Typography> */}

        <NavItem to={ROUTES.ABOUT} label="אודות" />

        <Typography color={LightBrown} sx={{ mt: 0.5 }}>
          _
        </Typography>

        <NavItem to={CATEGORY_BTN.ALL_PRODUCTS} label="המוצרים שלנו" />

        <Typography color={LightBrown} sx={{ mt: 0.5 }}>
          _
        </Typography>

        <NavItem to={ROUTES.ROOT} label="ראשי" />
      </Box>
    </Box>
  );
};

export default MiddleNavBar;
