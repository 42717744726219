import { Box } from "@mui/material";
import React from "react";
import NavBarLink from "../../../components/NavBarLink";
import ROUTES from "../../../../routes/routessModel";

const LeftNavBarLogo = () => {
  return (
    <NavBarLink to={ROUTES.ROOT}>
      <Box
        component="img"
        sx={{
          cursor: "pointer",
          height: 70,
        }}
        alt="Your logo."
        src="/assets/images/barLogo.png"
      />
    </NavBarLink>
  );
};

export default LeftNavBarLogo;
