import { useCallback, useState } from "react";
import CardInterface from "../interface/CardInterface";
import {
  createCard,
  deleteCard,
  getCard,
  getCards,
  getCardsByCategory,
} from "../services/cardApi";
import { useSnack } from "../../providers/SnackbarProvider";
import useAxiosinterceptors from "../../hooks/useAxiosinterceptors";
import ROUTES from "../../routes/routessModel";
import { useNavigate } from "react-router-dom";

type CardsType = null | CardInterface[];
type CardType = null | CardInterface;
type ErrorType = null | string;

const useCards = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cards, setCards] = useState<null | CardInterface[]>(null);
  const [card, setCard] = useState<null | CardInterface>(null);
  const snack = useSnack();
  useAxiosinterceptors();

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8181";
  const requestStatus = (
    loading: boolean,
    errorMessage: ErrorType,
    cards: CardsType,
    card: CardType = null
  ) => {
    setLoading(loading);
    setError(errorMessage);
    setCards(cards);
    setCard(card);
  };

  const handleGetCards = async () => {
    try {
      setLoading(true);
      const cards = await getCards();

      cards.map((card) => {
        card.imageUrl = `${apiUrl}/images/${card.imageUrl}`;
      });
      requestStatus(false, null, cards);
    } catch (error) {
      if (typeof error === "string") requestStatus(false, error, null, null);
    }
  };
  const handleGetCardsByCategory = async (category: string) => {
    try {
      setLoading(true);
      const cards = await getCardsByCategory(category);
      cards.map((card) => {
        card.imageUrl = `${apiUrl}/images/${card.imageUrl}`;
      });
      requestStatus(false, null, cards);
    } catch (error) {
      if (typeof error === "string") requestStatus(false, error, null, null);
    }
  };
  const handleGetCard = async (cardId: string) => {
    try {
      setLoading(true);
      const card = await getCard(cardId);
      if (card.imageUrl) {
        card.imageUrl = `${apiUrl}/images/${card.imageUrl}`;
      }

      requestStatus(false, null, null, card);
      return card;
    } catch (error) {
      if (typeof error === "string") requestStatus(false, error, null, null);
    }
  };
  const handleDeleteCard = useCallback(async (cardId: string) => {
    try {
      setLoading(true);
      await deleteCard(cardId);
      snack("success", "המוצר נמחק בהצלחה");
    } catch (error) {
      if (typeof error === "string") return requestStatus(false, error, null);
    }
  }, []);

  const HandleCreateCard = async (cardFromClient: FormData) => {
    try {
      setLoading(true);
      const card = await createCard(cardFromClient);
      requestStatus(false, null, null, card);
      snack("success", "מוצר חדש עלה בהצלחה");
      navigate(ROUTES.ROOT);
    } catch (error) {
      if (typeof error === "string") return requestStatus(false, error, null);
    }
  };

  return {
    isLoading,
    card,
    error,
    cards,
    handleGetCards,
    handleGetCard,
    handleDeleteCard,
    handleGetCardsByCategory,
    HandleCreateCard,
  };
};

export default useCards;
