import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import Card from "../../../../../cards/components/card/Card";
import Spinner from "../../../../../components/Spinner";
import Error from "../../../../../components/Error";
import useCards from "../../../../../cards/hooks/useCards";

const SilckCardsComp = () => {
  const { cards, error, isLoading, handleGetCards, handleDeleteCard } =
    useCards();

  useEffect(() => {
    handleGetCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDeleteCard = async (cardId: string) => {
    await handleDeleteCard(cardId);
    await handleGetCards();
  };

  const screen = () => {
    let width = 0;
    if (window.innerWidth > 700) {
      width = 4;
      return width;
    } else {
      width = 2;
      return width;
    }
  };
  const settings = {
    infinite: true,
    slidesToShow: screen(),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1800,
    pauseOnHover: true,
  };
  return (
    <Box>
      {isLoading && <Spinner />}
      {error && <Error errorMessage={error} />}
      <Slider {...settings}>
        {cards &&
          cards.map((card) => (
            <Card card={card} onDelete={onDeleteCard} key={card._id} />
          ))}
      </Slider>
    </Box>
  );
};

export default SilckCardsComp;
