import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NavBarLink from "../../layout/components/NavBarLink";
import { brown } from "@mui/material/colors";

type Props = {
  label: string;
  to: string;
  color?: string;
};

const NavItemSlick: React.FC<Props> = ({ label, to, color }) => {
  const MediumBrown = brown[700];
  return (
    <NavBarLink to={to} color={color}>
      <Button
        sx={{
          backgroundColor: "#ee9ca7",
          borderRadius: "10px",
          border: `2px dotted ${brown[700]}`,
        }}
      >
        <Typography
          sx={{ typography: { xs: "body1" } }}
          color={MediumBrown}
          variant="h6"
          component="h2"
        >
          {label}
        </Typography>
      </Button>
    </NavBarLink>
  );
};

export default NavItemSlick;
