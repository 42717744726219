import Joi from "joi";

const cardSchema = {
  title: Joi.string().min(2).max(256).required(),
  subtitle: Joi.string().min(2).max(256).required(),

  imageUrl: Joi.string(),
  price: Joi.string(),
  category: Joi.string(),
};

export default cardSchema;
