import { Box, CardActions, IconButton } from "@mui/material";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

import CardDeleteDialog from "./CardDeleteDialog";

type Props = {
  onDelete: (id: string) => any;
  cardId: string;
};

const CardActionBar: React.FC<Props> = ({ onDelete, cardId }) => {
  const [isDialogOpen, setDialog] = useState(false);

  const handleDialog = (term?: string) => {
    if (term === "open") return setDialog(true);
    setDialog(false);
  };
  const handleDeleteCard = () => {
    handleDialog();
    onDelete(cardId);
  };
  return (
    <>
      <CardActions
        disableSpacing
        sx={{ pt: 0, justifyContent: "space-between" }}
      >
        <Box></Box>

        <Box>
          <IconButton onClick={() => handleDialog("open")} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardActions>
      <CardDeleteDialog
        isDialogOpen={isDialogOpen}
        onChangeDialog={handleDialog}
        onDelete={handleDeleteCard}
      />
    </>
  );
};

export default CardActionBar;
