import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ROUTES, { CATEGORY } from "./routessModel";
import HomePage from "../pages/homepage/HomePage";
import AboutPage from "../pages/aboutPage/AboutPage";
import ErrorPage from "../pages/ErrorPage";
import LoginPage from "../user/pages/LoginPage";
import CardDetailsPage from "../cards/pages/CardDetailsPage";
import Categories from "../pages/categories/Categories";
import CardsPagee from "../cards/pages/CardsPagee";
import CardByCategory from "../cards/pages/CardByCategory";
import CreateCardPage from "../cards/pages/CreateCardPage";
import AllPics from "../cardsImages/pages/AllPics";
import UploadImages from "../cardsImages/components/UploadImage";

const Router = () => {
  return (
    <Routes>
      <Route path={ROUTES.ROOT} element={<HomePage />} />
      <Route path={ROUTES.PRODUCTS} element={<AboutPage />} />
      <Route path={ROUTES.ABOUT} element={<AboutPage />} />
      <Route path={ROUTES.KASHRUT} element={<AboutPage />} />
      <Route path={ROUTES.CONTENT} element={<AboutPage />} />
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.CREATE_CARD} element={<CreateCardPage />} />

      <Route
        path={`${ROUTES.PRODUCTS_DETAILS}/:cardId`}
        element={<CardDetailsPage />}
      />
      <Route path={`${ROUTES.IMAGES}/:cardId`} element={<AllPics />} />
      <Route
        path={`${ROUTES.UPLOAD_IMAGES}/:cardId`}
        element={<UploadImages />}
      />
      {/* //// change the element  */}
      <Route path={ROUTES.CATEGORIES} element={<Categories />}>
        <Route path={CATEGORY.ALL_PRODUCTS} element={<CardsPagee />} />
        <Route path={CATEGORY.CAKE} element={<CardByCategory />} />
        <Route path={CATEGORY.FRUIT_TRAY} element={<CardByCategory />} />
        <Route path={CATEGORY.CANDY} element={<CardByCategory />} />
        <Route path={CATEGORY.MOUSSE_CAKE} element={<CardByCategory />} />
        <Route path={CATEGORY.STRIPED_CAKE} element={<CardByCategory />} />
        <Route path={CATEGORY.DESSERT_CUPS} element={<CardByCategory />} />
        <Route path={CATEGORY.PESONAL_DESSERTS} element={<CardByCategory />} />
        <Route path={CATEGORY.BRANDED_CUPCAKES} element={<CardByCategory />} />
      </Route>

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;
