import { Box } from "@mui/material";
import React from "react";

const RightBackGroundImg = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: { xs: -60, md: -100 },
        right: { xs: "20%", md: "10%" },
        transform: "translateX(50%)",
        width: { xs: "200px", md: "300px" },
        height: { xs: "200px", md: "300px" },
        background: `url('/assets/images/homePageBgCake.png') no-repeat center center`,
        backgroundSize: "cover",
        borderRadius: "50%",
        zIndex: 1,
      }}
    />
  );
};

export default RightBackGroundImg;
