import { Grid } from "@mui/material";
import CardInterface from "../interface/CardInterface";
import Card from "./card/Card";
import React from "react";

type Props = {
  cards: CardInterface[];
  onDelete: (id: string) => void;
};
const Cards: React.FC<Props> = ({ cards, onDelete }) => {
  return (
    <Grid container spacing={2} pb={2}>
      {cards.map((card: CardInterface) => (
        <Grid item key={card._id} xs={12} sm={6} md={4} lg={4}>
          {" "}
          <Card onDelete={onDelete} card={card} key={card._id} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
