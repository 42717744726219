const ROUTES = {
  ROOT: "/",
  ABOUT: "/about",
  PRODUCTS: "/products",
  KASHRUT: "/kashrut",
  CONTENT: "/content-us",
  LOGIN: "/login",
  PRODUCTS_DETAILS: "/product-info",
  EDIT_PAGE: "/product-edit",
  CREATE_CARD: "/Create-card",
  CATEGORIES: "/categories",
  IMAGES: "/more-images",
  UPLOAD_IMAGES: "/upload-image",
};
export const CATEGORY_BTN = {
  CAKE: "/categories/cake",
  FRUIT_TRAY: "/categories/fruit-tray",
  CANDY: "/categories/candy",
  ALL_PRODUCTS: "/categories/all",
  MOUSSE_CAKE: "/categories/mousse-cakes",
  STRIPED_CAKE: "/categories/striped-cakes",
  DESSERT_CUPS: "/categories/dessert-cups",
  PESONAL_DESSERTS: "/categories/personalized-desserts",
  BRANDED_CUPCAKES: "/categories/branded-cupcakes",
};
export const CATEGORY = {
  CAKE: "cake", //
  FRUIT_TRAY: "fruit-tray", //
  CANDY: "candy",
  ALL_PRODUCTS: "all", //
  MOUSSE_CAKE: "mousse-cakes", //
  STRIPED_CAKE: "striped-cakes",
  DESSERT_CUPS: "dessert-cups",
  PESONAL_DESSERTS: "personalized-desserts",
  BRANDED_CUPCAKES: "branded-cupcakes", //
};
export default ROUTES;
