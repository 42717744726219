import React from "react";
import { Box, Container, Typography } from "@mui/material";

import SilckCardsComp from "./components/SilckCardsComp";
import { brown } from "@mui/material/colors";
import CategoriesMenu from "../../../../components/categoriesMenu";

const SlickCards = () => {
  const MediumBrown = brown[700];
  return (
    <Box
      minHeight="60vh"
      sx={{
        backgroundImage:
          "linear-gradient( rgba(255, 243, 249, 0.8), rgba(255, 243, 249, 0.8)),url(/assets/images/backGroundPic.png)",
        backgroundSize: "cover",
        borderTop: `4px solid ${brown[700]}`,
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container>
        <Box mt={2}>
          {" "}
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={4}
            variant="h3"
            component="h1"
            color={MediumBrown}
          >
            המוצרים שלנו
          </Typography>
          <CategoriesMenu />
          <SilckCardsComp />
        </Box>
      </Container>
    </Box>
  );
};

export default SlickCards;
