import { Box, Container, Grid, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";
import { brown, grey, pink } from "@mui/material/colors";

import React from "react";

const AboutContent: React.FC = () => {
  const { ref: leftTextRef, inView: leftTextInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: rightTextRef, inView: rightTextInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Animations for text elements
  const leftTextAnimation = useSpring({
    transform: leftTextInView ? "translateX(0)" : "translateX(-50%)",
    opacity: leftTextInView ? 1 : 0,
    config: { duration: 1000 },
  });

  const rightTextAnimation = useSpring({
    transform: rightTextInView ? "translateX(0)" : "translateX(50%)",
    opacity: rightTextInView ? 1 : 0,
    config: { duration: 1000 },
  });

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          textAlign: "center",
          mb: 6,
          position: "relative",
          zIndex: 2,
          "&::after": {
            content: '""',
            width: "60px",
            height: "4px",
            background: pink[400],
            display: "block",
            margin: "20px auto 0",
            borderRadius: "2px",
          },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: brown[800],
          }}
        >
          קצת עלינו
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <animated.div style={leftTextAnimation}>
            <Typography
              variant="h6"
              ref={leftTextRef}
              sx={{
                color: grey[700],
                lineHeight: 1.8,
                textAlign: "justify",
                fontSize: "1.2rem",
                mb: 4,
                px: { xs: 2, md: 4 },
              }}
            >
              ברוכים הבאים ל-בר מתוקים, המקום שבו חלומות מתוקים מתגשמים! אנו
              מתמחים ביצירת עוגות בהתאמה אישית לכל אירוע מיוחד, ובעיקר לימי
              הולדת. אנו שואפים להעניק לכם עוגות באיכות הגבוהה ביותר, עם עיצוב
              ייחודי וטעם בלתי נשכח. כל עוגה מיוצרת באהבה ובתשומת לב לפרטים
              הקטנים, כדי לוודא שכל לקוח מקבל בדיוק את מה שהוא רוצה.
            </Typography>
          </animated.div>
        </Grid>
        <Grid item xs={12}>
          <animated.div style={rightTextAnimation}>
            <Typography
              variant="h6"
              ref={rightTextRef}
              sx={{
                color: grey[700],
                lineHeight: 1.8,
                textAlign: "justify",
                fontSize: "1.2rem",
                mb: 4,
                px: { xs: 2, md: 4 },
              }}
            >
              אצלנו, איכות היא לא רק מילה, אלא דרך חיים. אנו משתמשים ברכיבים
              הטובים ביותר כדי להבטיח שכל ביס יהיה חוויה קולינרית יוצאת דופן. לא
              משנה מה הסגנון או הנושא, אנו כאן כדי להפוך את החזון שלכם למציאות
              מתוקה.
            </Typography>
          </animated.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutContent;
