import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../user/provider/UserProvider";
import { getImages } from "../services/imageApi";
import { ImageData } from "../interface/imageInterface";
import { Box, Fab, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ROUTES from "../../routes/routessModel";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import DeleteImage from "../components/DeleteImage";
const AllPics: React.FC = () => {
  const { cardId } = useParams<{ cardId: string }>();
  const [images, setImages] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const { user } = useUser();
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8181";
  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const data = await getImages(cardId);
        data.map((image) => {
          image.imageUrl = `${apiUrl}/uploads/${image.imageUrl}`;
        });
        setImages(data);
      } catch (err) {
        console.error("Error fetching images:", err);
        setError("Failed to load images.");
      } finally {
        setLoading(false);
      }
    };

    if (cardId) {
      fetchImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorMessage={error} />;
  }

  return (
    <Box
      sx={{
        background: `linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%)`,
        backgroundRepeat: "repeat",
        minHeight: "90vh",
      }}
    >
      <Box sx={{ padding: 3, direction: "rtl" }}>
        <Typography
          variant="h4"
          fontWeight={500}
          color="#b5816b"
          sx={{ WebkitTextStroke: "0.6px black" }}
        >
          עוד תמונות :
        </Typography>
        <Grid container spacing={2} mt={1}>
          {images.map((img) => (
            <Grid item key={img._id} xs={12} sm={6} md={4}>
              <Box>
                <img
                  loading="lazy"
                  src={img.imageUrl}
                  alt="Product"
                  style={{
                    width: "100%",

                    border: "5px solid #555",
                  }}
                />
                {user && user.isAdmin && <DeleteImage imageId={img._id} />}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {user && user.isAdmin && (
        <Fab
          onClick={() => navigate(`${ROUTES.UPLOAD_IMAGES}/${cardId}`)}
          color="primary"
          aria-label="add"
          sx={{
            position: "sticky",
            left: "95%",
            bottom: "97%",
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  );
};

export default AllPics;
