import React from "react";
import { Outlet } from "react-router-dom";
import { CATEGORY_BTN } from "../routes/routessModel";
import Slider from "react-slick";
import CategoriesButton from "../pages/categories/component/CategoriesButton";

const CategoriesMenu = () => {
  const screen = () => {
    let width = 0;
    if (window.innerWidth > 700) {
      width = 5;
      return width;
    } else {
      width = 3;
      return width;
    }
  };
  const settings = {
    infinite: true,
    slidesToShow: screen(),
    swipeToSlide: true,
  };
  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          <CategoriesButton label="עוגות" to={CATEGORY_BTN.CAKE} />
          <CategoriesButton label="מגשי פירות" to={CATEGORY_BTN.FRUIT_TRAY} />
          <CategoriesButton label="עוגות מוס" to={CATEGORY_BTN.MOUSSE_CAKE} />
          <CategoriesButton label="עוגות פס" to={CATEGORY_BTN.STRIPED_CAKE} />
          <CategoriesButton
            label="קינוחי כוסות"
            to={CATEGORY_BTN.DESSERT_CUPS}
          />
          <CategoriesButton
            label="קינוחים אישיים"
            to={CATEGORY_BTN.PESONAL_DESSERTS}
          />
          <CategoriesButton
            label="קאפקייקס ממותגים"
            to={CATEGORY_BTN.BRANDED_CUPCAKES}
          />{" "}
          {/* <CategoriesButton label="ממתקים" to={CATEGORY_BTN.CANDY} /> */}
        </Slider>
      </div>
      <br />
      <Outlet />
    </>
  );
};

export default CategoriesMenu;
