import React, { ChangeEvent, FC } from "react";
import TextField from "@mui/material/TextField";
import { makeFirstLetterCapital } from "../utils/algoMethods";
import Grid from "@mui/material/Grid";
import { MenuItem } from "@mui/material";

type BreakPointsKeyTypes = "xs" | "sm" | "md" | "lg" | "xl";
type VariantType = "filled" | "outlined" | "standard";
type BreakPointsValueTypes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type arrayObj = [any];
type Props = {
  variant?: VariantType;
  //   type?: string;
  name: string;
  data: Record<string, unknown>;
  label: string;
  required?: boolean;
  error?: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  breakPoints?: Partial<Record<BreakPointsKeyTypes, BreakPointsValueTypes>>;
};

const Selectinput: React.FC<Props> = ({
  variant = "outlined",
  //   type = "text",
  name,
  data,
  label,
  required = true,
  error,
  onInputChange,
  breakPoints = { xs: 12 },
}) => {
  const currencies = [
    {
      value: "cake",
      label: "עוגות",
    },
    {
      value: "fruit-tray",
      label: "מגשי פירות",
    },
    {
      value: "packaging",
      label: "מארזים",
    },
    {
      value: "mousse-cakes",
      label: "עוגות מוס",
    },
    {
      value: "striped-cakes",
      label: "עוגות פס",
    },
    {
      value: "dessert-cups",
      label: "קינוחי כוסות",
    },
    {
      value: "personalized-desserts",
      label: "קינוחי אישיים",
    },
    {
      value: "branded-cupcakes",
      label: "קפקייקס ממותגים",
    },
    {
      value: "candy",
      label: "ממתקים",
    },
  ];
  return (
    <Grid item {...breakPoints}>
      <TextField
        variant={variant}
        label={makeFirstLetterCapital(label)}
        // type={type}
        id={name}
        name={name}
        value={data[name] ? data[name] : ""}
        required={required}
        helperText={error}
        error={!!error}
        onChange={onInputChange}
        fullWidth
        autoComplete="off"
        select
        // defaultValue="עוגת יום הולדת"
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default React.memo(Selectinput);
//  <TextField
//    id="outlined-select-currency"
//    select
//    label="Select"
//    defaultValue="EUR"
//    helperText="Please select your currency"
//  >
//    {currencies.map((option) => (
//      <MenuItem key={option.value} value={option.value}>
//        {option.label}
//      </MenuItem>
//    ))}
//  </TextField>;
